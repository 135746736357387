<template>
    <div>
      <!-- Display TVA -->
      <v-simple-table>
        <thead>
          <tr>
            <th>TVA (%) </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ generalSetting.tva !== null ? generalSetting.tva : 'TVA vide' }}</td>
            <td>
              <!-- Display Button and Input Field in a Row -->
              <v-row align="center" no-gutters>
                <v-col cols="auto">
                  <v-text-field 
                    v-model="tvaValue" 
                    label="Entrez TVA" 
                    type="number"
                    solo
                    class="my-text-field"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <!-- If TVA is null, display "Add" -->
                  <v-btn 
                    v-if="generalSetting.tva === null" 
                    @click="addGeneralSetting" 
                    color="primary"
                    class="my-btn add-btn"
                  >
                    Ajouter TVA
                  </v-btn>
  
                  <!-- If TVA exists, display "Edit" -->
                  <v-btn 
                    v-else 
                    @click="editGeneralSetting" 
                    color="success"
                    class="my-btn edit-btn"
                  >
                    Modifier TVA
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
  
      <!-- Success Dialog -->
      <v-dialog v-model="dialogVisible" max-width="290">
        <v-card>
          <v-card-title class="headline">Succès</v-card-title>
          <v-card-text>TVA a été modifié avec succès !</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogVisible = false">ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Constant from "@/utils/constants";

  export default {
    data() {
      return {
        generalSetting: {
          id: null,
          tva: null
        },
        tvaValue: null, // Input for TVA
        dialogVisible: false // For success dialog visibility
      };
    },
    created() {
      this.fetchGeneralSetting();
    },
    methods: {
      // Fetch the current Parametre
      fetchGeneralSetting() {
        axios
          .get(Constant.QORANI+`library/generalSetting/get`)
          .then(response => {
            this.generalSetting = response.data;
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des paramètres généraux :', error);
          });
      },
  
      // Add a new setting
      addGeneralSetting() {
        if (this.validateTva()) {
          const parametre = {
            tva: this.tvaValue
          };
          axios
            .post(Constant.QORANI+`library/generalSetting/add`, parametre)
            .then(response => {
              console.log('TVA ajoutée :', response.data);
              this.fetchGeneralSetting(); // Refresh data
            })
            .catch(error => {
              console.error('Error adding general setting:', error);
            });
        }
      },
  
      // Edit an existing setting
      editGeneralSetting() {
        if (this.validateTva()) {
          const parametre = {
            tva: this.tvaValue
          };
          axios
            .post(Constant.QORANI+`library/generalSetting/edit/${this.generalSetting.id}`, parametre)
            .then(response => {
              console.log('TVA mise à jour :', response.data);
              this.fetchGeneralSetting();
              this.dialogVisible = true; // Show success dialog
              this.tvaValue = null; // Clear input field
            })
            .catch(error => {
              console.error('Error updating general setting:', error);
            });
        }
      },
  
      // Validate TVA
      validateTva() {
        if (!this.tvaValue || this.tvaValue <= 0) {
          alert('Veuillez entrer une valeur TVA valide.');
          return false;
        }
        return true;
      }
    }
  };
  </script>
  
  <style scoped>
  .my-text-field {
    max-width: 200px;
    margin-right: 30px; 
    padding-top: 30px;
  }
  
  .my-btn {
    padding: 8px 16px; 
    margin: 4px;
  }
  
  .add-btn {
    background-color: #007bff; 
    color: #fff; 
  }
  
  .edit-btn {
    background-color: #28a745; 
    color: #fff; 
  }
  </style>
  